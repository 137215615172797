<template>
  <div>
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedOffer"
      @clearSelectedObject="selectedOffer = null"
    >
      <template v-slot:list="{ item }">
        <ActionList
          v-if="item.key === 'active'"
          :key="item.key"
          :filters="[]"
          :load-items-callback="getOffers"
          @actionSuccess="getOffers"
          :fixed-filters="activeFixedFilters"
          @actionFailure="getOffers"
          empty-icon="mdi-cog"
          :empty-text="$t('noOffersFound')"
          v-on="$listeners"
          :call-to-actions="callToActions"
        >
          <template v-slot:item="{ item, index }">
            <OfferListItem
              :offer="item"
              :key="item.uuid"
              @selectOffer="selectOffer"
            />
            <v-divider
              inset
              :key="index"
            />
          </template>
        </ActionList>
        <ActionList
          v-if="item.key === 'inactive'"
          :key="item.key"
          :filters="[]"
          :load-items-callback="getOffers"
          :fixed-filters="inactiveFixedFilters"
          @actionSuccess="getOffers"
          @actionFailure="getOffers"
          empty-icon="mdi-cog"
          :empty-text="$t('noOffersFound')"
          v-on="$listeners"
          :call-to-actions="callToActions"
        >
          <template v-slot:item="{ item, index }">
            <OfferListItem
              :offer="item"
              :key="item.uuid"
              @selectOffer="selectOffer"
            />
            <v-divider
              inset
              :key="index"
            />
          </template>
        </ActionList>
        <ActionList
          v-if="item.key === 'all'"
          :key="item.key"
          :filters="[]"
          :load-items-callback="getOffers"
          @actionSuccess="getOffers"
          @actionFailure="getOffers"
          empty-icon="mdi-cog"
          :empty-text="$t('noOffersFound')"
          v-on="$listeners"
          :call-to-actions="callToActions"
        >
          <template v-slot:item="{ item, index }">
            <OfferListItem
              :offer="item"
              :key="item.uuid"
              @selectOffer="selectOffer"
            />
            <v-divider
              inset
              :key="index"
            />
          </template>
        </ActionList>
      </template>
      <template slot="profile">
        <OfferProfile
          v-if="selectedOffer"
          :offer="selectedOffer"
          @updateProfile="updateProfile"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
export default {
    name: 'Offers',
    components: {
      'OfferListItem': () => import('@/components/offers/OfferListItem'),
      'OfferProfile': () => import('@/components/offers/OfferProfile'),
      'ExtendedView': () => import('@/components/common/ExtendedView'),
			'ActionList': () => import('@/components/common/list/ActionList')
    },
    data() {
      return {
        selectedOffer: null, 
        callToActions: [
          {
            title: this.$t('addOffer'), 
            callback: this.showOfferDialog
          }
        ], 
        activeFixedFilters: [
          {
            key: 'active', 
            operator: 'equals', 
            value: true,
            type: 'boolean'
          }
        ], 
        inactiveFixedFilters: [
          {
            key: 'active', 
            operator: 'equals', 
            value: false,
            type: 'boolean'
          }
        ]
      }
    }, 
    methods: {
      selectOffer(offer) {
        this.selectedOffer = offer
      },
      getOffers(limit, skip, conditions) {
        return this.$store.dispatch('getOffers', {limit, conditions})
      }, 
      showOfferDialog() {
        this.$store.commit('updateDataToMutate', {
          title: this.$t('createOffer'),
          objectToMutate: {
            active: true, 
            compatibleWithOtherOffers: false,
            discountPercent: null, 
            type: null, 
            items: [],
            conditions: []
          }, 
          formComponent: 'components/offers/OfferForm', 
          saveCallback: function(newOffer) {
            if(newOffer.items.length > 0) {
              const itemIds = newOffer.items.map(item => item.uuid)
              newOffer.items = itemIds
            }
            if(newOffer.conditions.length > 0) {
              newOffer.conditions.map(condition => condition.floatValue = parseFloat(condition.floatValue))
            }
            this.$store.dispatch('createOffer', newOffer).then(result => {
              if(result) {
                this.$store.commit('updateActionSuccess', {
                  message: this.$t('success'), 
                  subMessage: this.$t('successfullyCreatedOffer'),  
                })
              } else {
                this.$store.commit('updateActionError', {
                  message: this.$t('error'), 
                  subMessage: this.$t('couldNotCreateOffer')
                })
              }
            }).catch(() => {
              this.$store.commit('updateActionError', {
                message: this.$t('error'), 
                subMessage: this.$t('couldNotCreateOffer')
              })
            })
          }
        })
      },
      updateProfile(updatedValues) {
        for(const key in updatedValues) {
          this.selectedOffer[key] = updatedValues[key]
        } 
      }, 
    }, 
    computed: {
      tabs() {
        return [
          {
            title: this.$t('active'),
            key: 'active'
          },
          {
            title: this.$t('inactive'),
            key: 'inactive'
          },
          {
            title: this.$t('all'),
            key: 'all'
          }
        ]
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	  },
    created() {
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportPayments
      })
    }
}

</script>
